import { StaticQuery, graphql } from "gatsby"

import Image from "gatsby-image"
import { PropTypes } from "prop-types"
import React from "react"

/**
 * Returns a a gatsby-image by its assetId using StaticQuery
 * @param {assetId} string Used to filter the results of the staticQuery and find the correct image
 * @param {className} string Used to add additional styling from parent
 * @param {alt} string A short string used for the images alt-tag
 * @param {force} bool If true, lazyloading will be avoided
 *
 */

const ImageByAssetId = ({ assetId, alt, className: extraClass, force }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityImageAsset {
            edges {
              node {
                assetId
                fluid(maxWidth: 2000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Image
          className={extraClass && extraClass}
          fluid={
            data.allSanityImageAsset.edges.find(asset => {
              return asset.node.assetId === assetId
            }).node.fluid
          }
          objectFit={"cover"}
          alt={`${alt}`}
          loading={force ? "eager" : "lazy"}
        />
      )}
    />
  )
}

ImageByAssetId.propTypes = {
  assetId: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
  force: PropTypes.bool,
}

ImageByAssetId.defaultProps = {
  assetId: null,
  className: null,
  alt: null,
  force: false,
}

export default ImageByAssetId
