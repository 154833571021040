import { Heading, Icon } from "dnb-ui-lib"

import { arrow_right as ArrowRight } from "dnb-ui-lib/icons"
import Image from "gatsby-image"
import ImageByAssetId from "../image-by-asset-id/ImageByAssetId"
import { Link } from "gatsby"
import { PropTypes } from "prop-types"
import React from "react"
import classNames from "classnames"
import * as styles from "./ArticleLink.module.scss"
import PortableText from "@sanity/block-content-to-react"

/**
 * Returns a styled article-link used to link to other or related articles
 * @param {type} string Specifies which styling options/ classes to use
 * @param {link} array The slug of the article
 * @param {category} object Article category from Sanity
 * @param {title} object Article title, used as a heading for the link
 * @param {description} object Description, ususally the article lede
 * @param {showDescription} bool Specifies if the descriptions should be displayed
 * @param {image} object Article image, either assetId or a fluid image
 * @param {hidden} bool If an article is hidden, a link will not be created
 *
 */

const descriptionSerializer = {
  types: {
    block: props => {
      return props.children
    },
  },
}

const ArticleLink = ({
  type,
  link,
  category,
  title,
  description,
  showDescription,
  image,
  hidden,
}) => {
  if (hidden) {
    return null
  }
  return (
    <Link to={`/${link}`} className={styles.article}>
      <div className={classNames(styles.linkContent, styles[type])}>
        {image && (
          <div className={styles.thumbContainer}>
            <div className={styles.articleImage}>
              {image.asset.fluid ? (
                <Image fluid={image.asset.fluid} alt={`${image.alt}`} />
              ) : (
                <ImageByAssetId
                  className={styles.articleImage}
                  assetId={image.asset.assetId}
                  alt={image.alt}
                />
              )}
            </div>
          </div>
        )}
        <div className={styles.articleText}>
          {category && (
            <div className={styles.category}>
              <span>{category.categoryName}</span>
            </div>
          )}
          <Heading className={styles.title}>{title}</Heading>
          {showDescription && (
            <div className={styles.description}>
              <PortableText
                blocks={description[0]}
                serializers={descriptionSerializer}
              />
            </div>
          )}
          {type === "tiny" && (
            <Icon
              icon={ArrowRight}
              size={"medium"}
              className={styles.arrow}
              aria-hidden
            />
          )}
        </div>
      </div>
    </Link>
  )
}

ArticleLink.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.object,
  description: PropTypes.array,
  showDescription: PropTypes.bool,
  image: PropTypes.object,
  hidden: PropTypes.bool,
}

ArticleLink.defaultProps = {
  type: "large",
  link: "#",
  title: null,
  category: null,
  description: null,
  showDescription: false,
  image: null,
  hidden: null,
}

export default ArticleLink
