import { Heading, Logo } from "dnb-ui-lib"

import React from "react"
import * as styles from "./DnbPresents.module.scss"
import { PropTypes } from "prop-types"

/**
 * Returns a component that displays an animated arrow to show users they can scroll down
 * @param {subtitle} string Added underneith the title field
 */

const DnbPresents = ({ subtitle }) => {
  return (
    <div className={styles.presentsContainer}>
      <div className={styles.logoContainer}>
        <Logo size="auto" className={styles.logo} />
        <div className={styles.tagline}>Presenterer</div>
      </div>
      <Heading className={styles.title}>HAVGAPET</Heading>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  )
}

DnbPresents.propTypes = {
  subtitle: PropTypes.string,
}

DnbPresents.defaultProps = {
  subtitle: null,
}

export default DnbPresents
