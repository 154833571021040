// extracted by mini-css-extract-plugin
export const article = "ArticleLink-module--article--38-HX";
export const linkContent = "ArticleLink-module--linkContent--2vBgO";
export const articleText = "ArticleLink-module--articleText--J9lXS";
export const thumbContainer = "ArticleLink-module--thumbContainer--3a9Pa";
export const articleImage = "ArticleLink-module--articleImage--1c1Aq";
export const large = "ArticleLink-module--large--1mXRj";
export const category = "ArticleLink-module--category--3uJNl";
export const title = "ArticleLink-module--title--2ms_g";
export const description = "ArticleLink-module--description--3T_Zi";
export const small = "ArticleLink-module--small--3kkmM";
export const tiny = "ArticleLink-module--tiny--2Wzrz";
export const arrow = "ArticleLink-module--arrow--1Q9E5";