import { PropTypes } from 'prop-types';
import React, { useState, useLayoutEffect, useRef } from 'react';
import * as styles from './CinematicPlayer.module.scss';
import SanityMuxPlayer from 'sanity-mux-player';

/**
 * Returns a videoplayer with controls
 * @param {desktopVideo} object Video that will be displayed on desktops
 * @param {mobileVideo} object Video that will be displayed on mobile devices
 *
 */

const useResize = (videoPlayer) => {
  const [currentWidth, setCurrentWidth] = useState(null);
  useLayoutEffect(() => {
    function updateWindowSize() {
      const video = videoPlayer.current.video.current;
      if (!video || video.paused) {
        const screenWidth = window.innerWidth;
        setCurrentWidth(screenWidth);
      }
    }
    updateWindowSize();
    window.addEventListener('resize', updateWindowSize);
    return () => window.removeEventListener('resize', updateWindowSize);
  }, []);
  return currentWidth;
};

const CinematicPlayer = ({ desktopVideo, mobileVideo }) => {
  const videoPlayer = useRef(null);
  const screenWidth = useResize(videoPlayer);
  const mobileBreakpoint = 720;
  return (
    <div className={styles.cinematicContainer}>
      {/* 
      <video
        width="400"
        src={"https://www.w3schools.com/html/mov_bbb.mp4"}
        controls
      />*/}
      <SanityMuxPlayer
        ref={videoPlayer}
        assetDocument={
          screenWidth < mobileBreakpoint && mobileVideo
            ? mobileVideo.asset
            : desktopVideo.asset
        }
        autoload={true}
        autoplay={false}
        loop={false}
        muted={false}
        showControls={true}
      />
    </div>
  );
};

CinematicPlayer.propTypes = {
  desktopVideo: PropTypes.object.isRequired,
  mobileVideo: PropTypes.object,
};

CinematicPlayer.defaultProps = {
  desktopVideo: null,
  mobileVideo: null,
};

export default CinematicPlayer;
